'use client'

// https://docs.sentry.io/platforms/javascript/guides/nextjs/manual-setup/#react-render-errors-in-app-router
// https://nextjs.org/docs/app/api-reference/file-conventions/error#global-errorjs

import * as Sentry from '@sentry/nextjs'
import ErrorPage from '@astrolab/web/common/Error/ErrorPage'
import { useEffect } from 'react'
import { CacheProvider } from '@chakra-ui/next-js'
import { ChakraProvider } from '@chakra-ui/react'
import theme from '@astrolab/web/theme/theme'

export default function GlobalError({
  error,
}: {
  error: Error & { digest?: string }
}) {
  useEffect(() => {
    Sentry.captureException(error)
  }, [error])

  return (
    <html>
      <body>
        <CacheProvider>
          <ChakraProvider theme={theme}>
            <ErrorPage message="An error occurred when loading the page." />
          </ChakraProvider>
        </CacheProvider>
      </body>
    </html>
  )
}
